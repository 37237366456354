import { API_ROOT } from './instances'
import testingTool from './services/testingTool'
import general from './services/general'
import users from './services/users'
import transactions from './services/transactions';
import fundsAdjustments from './services/fundsAdjustments';
import finxp from './services/finxp';
import payoro from './services/payoro';
import merchants from './services/merchants';
import auth from './services/auth';
import proxy from './services/proxy';
import balanceAlerts from './services/balanceAlerts';
import auditTrail from './services/auditTrail';
import reports from './services/reports';
import whitelistedTester from '@/api/services/whitelistedTester'
import ledgers from '@/api/services/ledgers'
import statements from '@/api/services/statements'
import merchantFinance from '@/api/services/merchantFinance'
import returnsMapping from '@/api/services/returnsMapping'

export const api = {
  testingTool: testingTool(API_ROOT),
  general: general(API_ROOT),
  users: users(API_ROOT),
  transactions: transactions(API_ROOT),
  fundsAdjustments: fundsAdjustments(API_ROOT),
  finxp: finxp(API_ROOT),
  payoro: payoro(API_ROOT),
  merchants: merchants(API_ROOT),
  auth: auth(API_ROOT),
  proxy: proxy(API_ROOT),
  balanceAlerts: balanceAlerts(API_ROOT),
  auditTrail: auditTrail(API_ROOT),
  reports: reports(API_ROOT),
  whitelistedTester: whitelistedTester(API_ROOT),
  ledgers: ledgers(API_ROOT),
  statements: statements(API_ROOT),
  merchantFinance: merchantFinance(API_ROOT),
  returnsMapping: returnsMapping(API_ROOT),
}
